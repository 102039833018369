<template>
  <v-btn icon target="_blank" href="/plannet/module/videosFormation/">
    <v-badge dot :value="hasNewVideo">
      <v-icon>mdi-video</v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import axios from "axios"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "ModuleVideo",
  computed: {
    newVideo() {
      return this.videos.length > 0
    }
  },
  data() {
    return {
      videos: [],
      hasNewVideo: false
    }
  },
  methods: {
    async checkVideo() {
      if (this.$store.getters.getAlertRights("video")) {
        let checkVideos = await PlaneteOnlineService.checkVideos()
        if (checkVideos && checkVideos.hasNewVideo) {
          this.hasNewVideo = true
        } else {
          this.hasNewVideo = false
        }
      }
      setTimeout(this.checkVideo, 300000) // 5 min
    },
  },
  created() {
    if (this.$store.getters.getAlertRights("video")) {
      this.checkVideo()
    }
  },
}
</script>

<style scoped>

</style>